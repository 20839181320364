<template>
  <div class="container">
    <div class="banner">
        <div class="w solution-bg">
            <h3>关于我们</h3>
            <p>亿企兴网络科技致力于中国领先的IT技术服务供应商，致力于为客户提供全方位、高性价比的综合IT技术服务<br />
行业解决方案、软件研发外包一体化服务，行业涉及IT互联网、金融、通信、能源、高端制造业<br />教育、物流、医疗等领域。
以客户满意为奋斗目标，服务了腾讯、招商银行、中国电子、施耐德<br />康佳、飞亚达、西门子等行业品牌企业，
并与众多大型品牌企业建立长期伙伴关系，通过提供专业<br />高效的服务，不断提高服务能力与客户的满意度，赢得客户的好评</p>
            <button @click="showKefuBox">在线咨询&nbsp;<i class="icon-im"></i></button>
        </div>
    </div>
    <!--//企业服务理念-->
    <div class="about-question">
        <div class="w">
            <h3 class="t">技术支持服务理念</h3>
            <ul>
                <li><span><i class="icon iconfont">&#xe641;</i></span><h3>IT服务治理全面</h3><p>IT服务全方面,全门类一站式服务</p></li>
                <li><span><i class="icon iconfont">&#xe6f5;</i></span><h3>技术研发力量雄厚</h3><p>10数年技术积累和项目实践</p></li>
                <li><span><i class="icon iconfont">&#xe74f;</i></span><h3>服务能力闭环</h3><p>全方位透明化项目跟踪/治理/交付</p></li>
                <li><span><i class="icon iconfont">&#xe74c;</i></span><h3>严格绩效考核</h3><p>有效的研发考评与激励机制</p></li>
                <li><span><i class="icon iconfont">&#xe757;</i></span><h3>资源分配合理</h3><p>保证高效的开发项目</p></li>
            </ul>
        </div>
    </div>
    <!--//企业文化-->
    <div class="knowledge">
        <div class="w">
            <h3>企业文化</h3>
            <p>“亿企”，致力于亿万企业IT网络服务治理，坚持以客户为中心，以领先的技术及优质的服务助力客户更好实现数字化转型。</p>
            <p>企业愿景：成为中国领先的IT技术服务供应商<br />
合作理念：以客户中心，坚持以技术和服务带给客户更大价值<br />
社会责任：实现客户机价值、员工价值、企业价值一体化、可持续发展</p>
            <ul>
                <li><i class="icon iconfont">&#xe735;</i>&nbsp;诚信</li>
                <li><i class="icon iconfont">&#xe87d;</i>&nbsp;创新</li>
                <li><i class="icon iconfont">&#xe82a;</i>&nbsp;合作</li>
                <li><i class="icon iconfont">&#xe87e;</i>&nbsp;敬业</li>
                <li><i class="icon iconfont">&#xe82b;</i>&nbsp;责任</li>
            </ul>
        </div>
    </div>
    <!--//员工精彩-->
    <div class="w goods">
        <h3>员工精彩</h3>
        <p>企业的成功取决于诸多因素,但人力资源总是企业生存和发展的第一资源,把员工职业生涯管理作为其人力资源开发与管理的重要手段.企业对员工职业发展进行规划管理,有利于企业吸收,培养,稳定优秀人才,也能够最大程度地激发员工工作激情,挖掘员工工作潜能,提高员工对企业的忠诚度和对工作的满意度.</p>
        <center><img src="../assets/ygjc001.jpg" style="width:30%" />&nbsp;
        <img src="../assets/ygjc002.jpg" style="width:30%" />&nbsp;
        <img src="../assets/ygjc003.jpg" style="width:30%" />&nbsp;
        <img src="../assets/ygjc0014.jpg" style="width:30%" />&nbsp;
        <img src="../assets/ygjc0015.jpg" style="width:30%" />&nbsp;
        <img src="../assets/ygjc0016.gif" style="width:30%" />
        </center>
    </div>
    <!--//公益在路上-->
    <div class="w public">
        <h3>公益在路上</h3>
        <p>履行社会责任、回馈社会大众的步伐将不会停歇。对于我们来说,只要企业在生长,公益永远在路上</p>
        <div class="running">
            <ul>
                <li><img src="../assets/gongyi001.png" /></li>
                <li><img src="../assets/gongyi002.png" /></li>
                <li><img src="../assets/gongyi003.png" /></li>
                <li><img src="../assets/gongyi004.png" /></li>
                <li><img src="../assets/gongyi005.png" /></li>
            </ul>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    methods: {
        showKefuBox: function()
        {
            this.$store.commit("setKefuStatus",true);
        }
    }
}
</script>

<style>
.banner h3{ font-size: 36px; color: #ffffff; font-weight: normal; padding-top: 60px; margin:0px;}
.banner p{ font-size: 18px; color: #ffffff; line-height: 30px;}
.banner button{ background-color: #ffffff; border: 1px #ffffff solid; border-radius: 3px; padding: 10px 40px; font-size: 18px; cursor: pointer; margin-top: 30px;}
.banner button i{ font-size: 30px; }
.solution-bg{ background: url("../assets/so_develop_f.png") right bottom no-repeat; height: 90%;}

.about-question h3.t{ font-size: 25px; font-weight: normal; margin-top: 50px;}
.about-question ul,.about-question ul li,.about-question ul li h3{ margin: 0px; padding: 0px; list-style: none; display: block; line-height: 30px;}
.about-question ul{  border-right: 1px #eeeeee solid; height: 230px; overflow: hidden; margin: 30px 0px;}
.about-question ul li{ width: 19%; overflow: hidden; float: left; text-align: center; height: 230px; padding-top: 40px; border-left: 1px #eeeeee solid; cursor: pointer; color: #000000;}
.about-question ul li span i{ font-size: 45px;}
.about-question ul li:hover{ color: #004172;}
.about-question ul li h3{ font-size: 18px;}
.about-question ul li p{ font-size: 14px;}

.knowledge{ background-color: #edecec; padding: 50px 0px;}
.knowledge .w{ overflow: hidden;}
.knowledge h3{ font-size: 36px; font-weight: normal; margin: 0px; padding: 0px;}
.knowledge p{ font-size: 18px; line-height: 40px;}
.knowledge ul,.knowledge ul li{ margin: 0px; padding: 0px; list-style: none; display: block; height: 150px;}
.knowledge ul{ width: 107%; border-radius: 5px; border: 1px #eeeeee solid;}
.knowledge ul li{ float: left; width: 19%; text-align:center; font-size: 25px; line-height: 180px; border-right: 1px #eeeeee solid;}
.knowledge ul{ background-color: #ffffff;}
.knowledge ul li i{ font-size: 60px;}

.goods{ padding: 50px 0px;}
.goods h3{ font-size: 36px; font-weight: normal; margin: 0px; padding: 0px;}
.goods p{ font-size: 18px; line-height: 40px;}

.public{ padding: 50px 0px;}
.public h3{ font-size: 36px; font-weight: normal; margin: 0px; padding: 0px;}
.public p{ font-size: 18px; line-height: 40px;}
.public .running{ overflow: hidden;}
.public .running ul{ width: 1300px;}
.public .running ul,.public .running ul li{ display: block; margin: 0px; padding: 0px; list-style: none;}
.public .running ul li{ width: 250px; height: 200px; background-color: #eeeeee; float: left; margin-right: 10px; border-radius: 5px;}
</style>
